import styled from "styled-components";

export const AiAgent = () => {
  return (
    <AiAgentContainer>
      <iframe
        src="https://ai-agent-dev.launchpharm.cloud"
        style={{ width: "100%", height: "100%", minHeight: "500px" }}
        frameBorder="0"
        allow="microphone"
      />
    </AiAgentContainer>
  );
};

const AiAgentContainer = styled.div`
  width: 100vw;
  margin-top: 48px;
  height: calc(100vh - 3rem - 14px - 110px);
  @media (max-width: 576px) {
    height: calc(100vh - 3rem - 14px - 174px);
    min-height: calc(100vh - 3rem - 14px - 174px);
  }
  min-height: calc(100vh - 3rem - 14px - 110px);
  overflow-x: hidden;
  overflow-y: hidden;
`;
